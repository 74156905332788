<template>
  <div
    class="endpoint_right_menu"
    id="endpoint_right_menu"
    @contextmenu="stop"
    @click="stop"
    @keydown="keyCheck"
    @keydown.ctrl="ctrlKeyCheck"
  >
    <a-input
      v-model.trim="tag"
      placeholder="请输入搜索关键字"
      @change="getGuessNodeInfoList"
      ref="tag"
    >
      <i slot="suffix" class="search"></i>
    </a-input>
    <div class="content" v-if="tagList.length > 0" ref="content">
      <template v-for="group in tagList">
        <h5 v-if="group.children.length > 0" style="margin: 0; color: #cbcbcb">
          {{ group.name }}节点列表
        </h5>
        <div style="margin: 5px 0">
          <div
            :class="{ tagNode: true, active: index == node.index }"
            v-for="node in group.children"
            :key="node.id"
            @click="checkNode(node)"
            :ref="'tagNode' + node.index"
          >
            <div class="nodeItem" :type="node.type" style="margin-left: 10px">
              <i
                class="nodeIcon"
                :style="{ backgroundImage: `url(${node.icon})` }"
                style="width: 20px; height: 20px"
              ></i>
              <div class="tagName">
                {{ node.name }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import lodash from "lodash";

export default {
  props: {
    menuList: Array,
    endpointMenuData: Object,
    pipelineTaskType: String,
    model: String,
    frameSelectionList: Object,
  },
  data() {
    return {
      tag: "",
      tagList: [
        {
          id: 1,
          name: "零对一",
          children: [],
        },
        {
          id: 2,
          name: "一对一",
          children: [],
        },
        {
          id: 3,
          name: "多对一",
          children: [],
        },
        {
          id: 4,
          name: "一对多",
          children: [],
        },
        {
          id: 5,
          name: "多对多",
          children: [],
        },
        {
          id: 6,
          name: "一对零",
          children: [],
        },
      ],
      timer: null,
      index: 0,
      ctrlKeyShow: false,
    };
  },
  computed: {
    noData() {
      return this.tagList.every((item) => {
        return item.children.length == 0;
      });
    },
    dataLength() {
      let length = 0;
      this.tagList.forEach((item) => {
        length += item.children.length;
      });
      return length;
    },
  },
  mounted() {
    let element = document.getElementById("endpoint_right_menu");
    element.style.top = this.endpointMenuData.top + "px";
    element.style.left = this.endpointMenuData.left + "px";
    this.index = 0;
    this.$refs.tag.focus();
    this.getGuessNodeInfoList();
  },
  methods: {
    ctrlKeyCheck() {
      this.ctrlKeyShow = true;
    },
    keyCheck(value) {
      if (value.which == 27) {
        this.$emit("panelClick");
        return;
      }
      if (!this.noData) {
        if (value.which == 38 && this.index > 1) {
          this.index--;
        }
        if (value.which == 40 && this.index < this.dataLength) {
          this.index++;
        }
        if (this.index > 0) {
          let offsetTop = this.$refs["tagNode" + this.index][0].offsetTop;
          let scrollTop = this.$refs.content.scrollTop;
          if (offsetTop > scrollTop + 400) {
            this.$refs.content.scrollTop = offsetTop + 35 - 400;
          } else if (offsetTop <= scrollTop + 35) {
            this.$refs.content.scrollTop -= 100 + scrollTop - offsetTop;
          } else if (this.index == 1) {
            this.$refs.content.scrollTop = 0;
          }
          if (value.which == 13 && this.index !== 0) {
            let ctrlKeyShow = this.ctrlKeyShow;
            this.ctrlKeyShow = false;
            this.tagList.forEach((group) => {
              group.children.forEach((node) => {
                if (this.index == node.index) {
                  this.checkNode(node, ctrlKeyShow);
                }
              });
            });
            this.tag = "";
            this.index = 0;
            this.tagList.forEach((group) => {
              group.children.splice(0);
            });
          }
        }
      }
    },
    getGuessNodeInfoList() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        let data = {
          pipelineTaskType: this.pipelineTaskType,
          tag: this.tag,
          collect: false,
          model: this.model,
        };

        if (!this.tag && this.endpointMenuData.node) {
          data.prevNodeType = this.endpointMenuData.node.type;
        }
        if (!this.endpointMenuData.node) {
          data.group = "多对一节点列表";
        }
        this.$http.get("/sysPipeline/nodeInfoList", data).then((res) => {
          if (res.result == 200) {
            this.tagList.forEach((group) => {
              group.children.splice(0);
            });
            if (res.data) {
              res.data.forEach((item) => {
                this.tagList.forEach((group) => {
                  if (group.name == item.group.split("节点列表")[0]) {
                    let node = this.getMenuByNodeId(item.id);
                    group.children.push(node);
                  }
                });
              });
              let index = 0;
              this.tagList.forEach((group) => {
                group.children.forEach((node) => {
                  index++;
                  this.$set(node, "index", index);
                });
              });
            }
          }
        });
      }, 200);
    },
    getMenuByNodeId(nodeId) {
      for (let i = 0; i < this.menuList.length; i++) {
        let children = this.menuList[i].children;
        for (let j = 0; j < children.length; j++) {
          if (children[j].id === nodeId) {
            return children[j];
          }
        }
      }
    },
    stop(e) {
      e.preventDefault();
      e.stopPropagation();
    },
    checkNode(node, ctrlKeyShow) {
      let menuNode = lodash.cloneDeep(node);
      let e = {
        clientX: 0,
        clientY: 0,
      };
      if (this.endpointMenuData.node) {
        e.clientX = this.endpointMenuData.node.left.split("px")[0] * 1 + 300;
        e.clientY = this.endpointMenuData.node.top.split("px")[0] * 1;
      } else {
        e.clientX =
          this.frameSelectionList.nodeList[
            this.frameSelectionList.nodeList.length - 1
          ].left.split("px")[0] *
            1 +
          300;
        e.clientY =
          this.frameSelectionList.nodeList[
            this.frameSelectionList.nodeList.length - 1
          ].top.split("px")[0] * 1;
      }
      this.$emit("addNode", e, menuNode, ctrlKeyShow);
    },
  },
};
</script>

<style scoped lang="scss">
.endpoint_right_menu {
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  background-color: #fff;
  padding: 5px;
  width: 400px;
  border: 1px solid #e1e1e1;
  box-shadow: 0 0 3px rgb(192, 196, 204);
  border-radius: 6px;
  z-index: 1000;

  &:deep(.ant-input) {
    height: 40px !important;
    line-height: 40px;
  }

  .search {
    width: 20px;
    height: 20px;
    background-image: url("../../assets/icon/search.png");
    background-size: 100% 100%;
    background-position: center;
    display: inline-block;
  }

  .content {
    max-height: 400px;
    overflow-y: scroll;
    padding: 5px;
    margin-top: 10px;
    &::-webkit-scrollbar {
      display: none;
    }

    .node {
      width: 10%;
      height: 56.8px;
      display: inline-block;
      border-radius: 5px;
      &:hover {
        /* 设置移动样式*/
        cursor: pointer;
        background-color: #f0f7ff;
      }
    }

    .tagNode {
      border-radius: 5px;
      height: 35px;
      cursor: pointer;
      &:hover {
        /* 设置移动样式*/
        background-color: #f0f7ff;
      }
      .nodeItem {
        justify-content: left;
      }
    }
    .active {
      background-color: #f0f7ff;
    }
    .nodeItem {
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      height: 100%;
      justify-content: center;
      .tagName {
        margin-left: 10px;
        color: #4a4a4a;
      }
    }
  }
}
</style>
