<template>
  <div class="userList">
    <div class="searchCondition" style="margin-top: 20px">
      <div class="searchConditionItem">
        <span>平台：</span>
        <a-select
          v-model="platformSearch"
          placeholder="请选择平台"
          showSearch
          optionFilterProp="children"
        >
          <a-select-option
            v-for="item in options"
            :key="item.code"
            :value="item.code"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchConditionItem">
        <span>用户名：</span>
        <a-input
          v-model.trim="userNameSearch"
          placeholder="请输入用户名"
          allowClear
          @change="allowClearChange"
          @pressEnter="query(1)"
        />
      </div>
      <div class="searchConditionItem">
        <span>accessKey：</span>
        <a-input
          v-model.trim="accessKeySearch"
          placeholder="请输入accessKey"
          allowClear
          @change="allowClearChange"
          @pressEnter="query(1)"
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="addUser()" icon="plus"
          >新增用户</a-button
        >
      </div>
    </div>

    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableData"
      :pagination="tablePagination"
      size="small"
      :rowKey="(record) => record.id"
      :loading="loadingTable"
    >
      <span slot="dayCount" slot-scope="text, record">
        <a-tooltip placement="top">
          <div slot="title">{{ record.dayCount }} / {{ record.dumpLimit }}</div>
          <el-progress
            color="rgba(142, 113, 199, 0.7)"
            v-if="record.progress !== false"
            :percentage="record.progress"
            :text-inside="true"
            :stroke-width="18"
          ></el-progress>
          <div v-else>-</div>
        </a-tooltip>
      </span>
      <span slot="enabled" slot-scope="text, record">
        <a-switch
          checked-children="已启用"
          un-checked-children="已禁用"
          v-model="record.enabled"
          @change="modify_data(record, true)"
        />
      </span>
      <span slot="action" slot-scope="text, record">
        <a-button type="link" @click="hitLibrary(record, 1)">撞库记录</a-button>
        <a-divider type="vertical" />
        <a-button type="link" @click="modify_data(record)">修改</a-button>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="left"
          okText="确认"
          cancelText="取消"
          @confirm="delete_pipeline(record)"
        >
          <template slot="title">确认是否删除{{ record.userName }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>

    <!-- 新增or编辑弹框 -->
    <a-modal
      :maskClosable="false"
      :title="addTitle"
      v-model="addDataShow"
      width="500px"
      :afterClose="addDataClose"
    >
      <template slot="title">
        <span class="dialog-title-my my-title" icon="plus">创建任务</span>
      </template>
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="平台:">
          <a-select
            v-model="platform"
            placeholder="请选择平台"
            style="width: 80%"
            @change="platChange"
            showSearch
            optionFilterProp="children"
          >
            <a-select-option
              v-for="item in options"
              :key="item.code"
              :value="item.code"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="通道:">
          <a-select
            v-model="dumpChannelId"
            placeholder="请选择通道"
            style="width: 80%"
          >
            <a-select-option
              v-for="item in dumpChannelIdList"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="用户名:">
          <a-input
            v-model="userName"
            placeholder="请输入用户名"
            style="width: 80%"
            allowClear
          />
        </a-form-item>
        <a-form-item label="accessKey:">
          <a-input
            v-model="accessKey"
            placeholder="请输入accessKey"
            style="width: 80%"
            allowClear
          />
        </a-form-item>
        <a-form-item label="accessSecret:">
          <a-input
            v-model="accessSecret"
            placeholder="请输入accessSecret"
            style="width: 80%"
            allowClear
          />
        </a-form-item>
        <a-form-item label="日调用限制:">
          <a-input
            v-model="dumpLimit"
            placeholder="请输入日调用限制"
            style="width: 80%"
            allowClear
            oninput="value=value.replace(/[^\d]/g,'')"
          />
        </a-form-item>
        <a-form-item label="回调地址:">
          <a-input
            v-model="callbackUrl"
            placeholder="请输入回调地址"
            style="width: 80%"
            allowClear
          />
        </a-form-item>
        <a-form-item label="ip:">
          <a-textarea
            v-model="ip"
            placeholder="请输入ip，一行一个"
            style="width: 80%"
            allowClear
            :autoSize="{ minRows: 4 }"
          />
        </a-form-item>
        <a-form-item label="启用:">
          <a-switch
            checked-children="已启用"
            un-checked-children="已禁用"
            v-model="enabled"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button type="primary" @click="addData_submit">确定</a-button>
          <a-button @click="addDataShow = false">取消</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      :maskClosable="false"
      :title="title"
      v-if="subtaskListShow"
      v-model="subtaskListShow"
      width="1000px"
      :afterClose="subtaskListClosed"
      :footer="null"
    >
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(subtaskListTableColumns)"
        :columns="subtaskListTableColumns"
        :dataSource="subtaskListTableData"
        :pagination="subtaskListTablePagination"
        size="small"
        :rowKey="(record) => record.id"
        :loading="subtaskListLoadingTable"
      >
      </a-table>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: "userList",
  data() {
    return {
      tableColumns: [
        {
          title: "序号",
          ellipsis: true,
          dataIndex: "id",
          align: "center",
          width: 60,
        },
        {
          title: "平台",
          ellipsis: true,
          dataIndex: "platformName",
          width: 130,
        },
        {
          title: "通道",
          ellipsis: true,
          dataIndex: "channel",
          width: 130,
        },
        {
          title: "用户名",
          ellipsis: true,
          dataIndex: "userName",
          width: 150,
        },
        {
          title: "accessKey",
          ellipsis: true,
          dataIndex: "accessKey",
          width: 180,
        },
        {
          title: "当日调用数量",
          ellipsis: true,
          dataIndex: "dayCount",
          scopedSlots: { customRender: "dayCount" },
          align: "center",
          width: 150,
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "enabled",
          scopedSlots: { customRender: "enabled" },
          align: "center",
          width: 100,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          align: "center",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 180,
        },
      ],
      tableData: [],
      tablePagination: {},
      loadingTable: false,
      currentPage: 1,
      platform: undefined,
      userName: "",
      accessKey: "",
      accessSecret: "",
      dumpLimit: "",
      ip: "",
      enabled: true,
      addTitle: "",
      addDataShow: false,
      editId: "",
      platformSearch: undefined,
      userNameSearch: "",
      accessKeySearch: "",
      options: [],
      dumpChannelIdList: [],
      dumpChannelId: undefined,
      subtaskListTableData: [],
      subtaskListTableColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          align: "center",
          width: 200,
        },
        {
          title: "日期",
          ellipsis: true,
          dataIndex: "date",
          align: "center",
          width: 200,
        },
        {
          title: "数量",
          ellipsis: true,
          dataIndex: "limit",
          align: "center",
          width: 200,
        },
      ],
      subtaskListTablePagination: {},
      subtaskListLoadingTable: false,
      subtaskListShow: false,
      subtaskCurrentPage: 1,
      userId: 0,
      callbackUrl: "",
      title: "",
      hitLibraryVal: {},
    };
  },
  mounted() {
    this.query(1);
  },
  methods: {
    subtaskCurrentPage_data(index) {
      this.hitLibrary(null, index);
    },
    subtaskListClosed() {
      this.subtaskListTableData = [];
    },
    hitLibrary(val, index) {
      this.subtaskListLoadingTable = true;
      if (val) {
        this.hitLibraryVal = val;
        this.userId = val.id;
      }
      this.title = `${this.hitLibraryVal.platformName}-${this.hitLibraryVal.userName}-撞库记录`;
      if (index) {
        this.subtaskCurrentPage = index;
      }
      let data = {
        pageNo: this.subtaskCurrentPage,
        pageSize: 10,
        userId: this.userId,
      };
      this.$http.get("/user/dumpLimitLog", data, "/apiPrefix").then((res) => {
        if (res.result === 200) {
          this.subtaskListTableData = res.data.records;
          this.subtaskListShow = true;
          this.subtaskListTablePagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: data.pageSize,
            current: data.pageNo,
            total: res.data.total,
            onChange: (current) => this.subtaskCurrentPage_data(current),
          };
          this.subtaskListLoadingTable = false;
        }
      });
    },
    // 平台下拉框改变
    platChange(val) {
      this.dumpChannelId = "";
      let arr = this.options.filter((el) => el.code === val);
      let data = {
        platFormId: arr[0].id,
      };
      // "",
      this.$http.get("/user/channelList", data, "/apiPrefix").then((res) => {
        if (res.result === 200) {
          this.dumpChannelIdList = res.data;
        }
      });
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 查询
    query(index) {
      this.loadingTable = true;
      if (index) {
        this.currentPage = index;
      }
      let data = {
        pageNo: this.currentPage,
        pageSize: 10,
        platform: this.platformSearch,
        userName: this.userNameSearch,
        accessKey: this.accessKeySearch,
      };
      this.getPlatformList();
      this.tableData.splice(0);
      this.$http.get("/user/page", data, "/apiPrefix").then((res) => {
        if (res.result === 200) {
          this.tableData.push(...res.data.records);
          this.tableData.forEach((item) => {
            if (item.dumpLimit > 0) {
              let num = (item.dayCount / item.dumpLimit) * 100;
              num = num.toFixed(2);
              this.$set(item, "progress", parseFloat(num));
            } else {
              this.$set(item, "progress", false);
            }
          });
          this.tablePagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: data.pageSize,
            current: data.pageNo,
            total: res.data.total,
            onChange: (current) => this.currentPage_data(current),
          };
          this.loadingTable = false;
        }
      });
    },
    // 查询平台
    getPlatformList() {
      this.$http.get("/user/platformList", null, "/apiPrefix").then((res) => {
        if (res.result === 200) {
          this.options = res.data;
        }
      });
    },
    // 翻页
    currentPage_data(index) {
      this.query(index);
    },
    addUser() {
      this.addTitle = "新增";
      this.addDataShow = true;
    },
    addDataClose() {
      this.platform = undefined;
      this.userName = "";
      this.accessKey = "";
      this.accessSecret = "";
      this.dumpLimit = "";
      this.dumpChannelId = undefined;
      this.callbackUrl = "";
      this.ip = "";
      this.enabled = true;
    },
    addData_submit() {
      let data = {
        platform: this.platform,
        userName: this.userName,
        accessKey: this.accessKey,
        accessSecret: this.accessSecret,
        dumpLimit: this.dumpLimit,
        dumpChannelId: this.dumpChannelId,
        ip: this.ip,
        enabled: this.enabled,
        callbackUrl: this.callbackUrl,
      };
      if (this.addTitle == "新增") {
        this.$http.json_post("/user/add", data, "/apiPrefix").then((res) => {
          this.$message.success("新增成功");
          this.query();
          this.addDataShow = false;
        });
      } else {
        data.id = this.editId;
        this.$http.json_post("/user/edit", data, "/apiPrefix").then((res) => {
          this.$message.success("修改成功");
          this.query();
          this.addDataShow = false;
        });
      }
    },
    // 修改
    modify_data(val, enable) {
      this.addTitle = "修改";
      let arr = this.options.filter((el) => el.code === val.platform);
      let data = {
        platFormId: arr[0].id,
      };
      this.$http.get("/user/channelList", data, "/apiPrefix").then((res) => {
        if (res.result === 200) {
          this.dumpChannelIdList = res.data;
        }
      });
      this.editId = val.id;
      this.platform = val.platform;
      this.userName = val.userName;
      this.accessKey = val.accessKey;
      this.accessSecret = val.accessSecret;
      this.dumpLimit = val.dumpLimit;
      this.dumpChannelId = val.dumpChannelId;
      this.callbackUrl = val.callbackUrl;
      this.ip = val.ip.replace(/,/g, "\n");
      this.enabled = val.enabled;
      if (enable) {
        this.addData_submit();
      } else {
        this.addDataShow = true;
      }
    },
    // 删除
    delete_pipeline(val) {
      let data = {
        id: val.id,
      };
      this.$http.get("/user/delete", data, "/apiPrefix").then((res) => {
        if (res.result == 200) {
          this.$message.success("删除成功");
          this.query();
        }
      });
    },
  },
};
</script>
