<template>
  <div class="dataOrigin">
    <div class="searchCondition">
      <div class="searchConditionItem">
        <span>名称：</span>
        <a-input
          v-model.trim="name"
          placeholder="请输入名称"
          allowClear
          @change="allowClearChange"
          @pressEnter="query(1)"
        />
      </div>
      <div class="searchConditionItem">
        <span>host：</span>
        <a-input
          v-model.trim="host"
          placeholder="请输入host"
          allowClear
          @change="allowClearChange"
          @pressEnter="query(1)"
        />
      </div>
      <div class="searchConditionItem">
        <span>x：</span>
        <a-input
          v-model.trim="x"
          placeholder="请输入x"
          allowClear
          @change="allowClearChange"
          @pressEnter="query(1)"
        />
      </div>
      <div class="searchConditionItem">
        <span>y：</span>
        <a-input
          v-model.trim="y"
          placeholder="请输入y"
          allowClear
          @change="allowClearChange"
          @pressEnter="query(1)"
        />
      </div>
      <div class="searchConditionItem">
        <span>类型：</span>
        <a-select v-model="type" placeholder="请选择类型" allowClear>
          <a-select-option
            v-for="item in typeList"
            :key="item.id"
            :value="item.type"
          >
            {{ item.type }}
          </a-select-option>
        </a-select>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">查询</a-button>
        <a-button type="primary" @click="addPipeline()" icon="plus"
          >新增数据源</a-button
        >
      </div>
    </div>

    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableData"
      :pagination="tablePagination"
      size="small"
      :rowKey="(record) => record.id"
      :loading="loading"
    >
      <span slot="enabled" slot-scope="text, record">
        <a-switch
          checked-children="已启用"
          un-checked-children="已禁用"
          v-model="record.enabled"
          @change="modify_data(record, true)"
        />
      </span>
      <span slot="action" slot-scope="text, record">
        <a-button type="link" @click="test_click(record)">连接测试</a-button>
        <a-divider type="vertical" />
        <a-button type="link" @click="modify_data(record)">修改</a-button>
        <a-divider type="vertical" />
        <a-popconfirm
          placement="left"
          okText="确认"
          cancelText="取消"
          @confirm="delete_pipeline(record)"
        >
          <template slot="title">确认是否删除{{ record.name }}</template>
          <a href="javascript:;" style="color: #ff4d4f">删除</a>
        </a-popconfirm>
      </span>
    </a-table>

    <!-- 新增数据源管理 -->
    <a-modal
      :maskClosable="false"
      :title="addTitle"
      v-model="addDataShow"
      width="500px"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="名称:">
          <a-input
            v-model="nameAdd"
            placeholder="请输入名称"
            style="width: 80%"
            allowClear
          />
        </a-form-item>
        <a-form-item label="database:">
          <a-input
            v-model="databaseNameAdd"
            placeholder="请输入database"
            style="width: 80%"
            allowClear
          />
        </a-form-item>
        <a-form-item label="类型:">
          <a-select v-model="typeAdd" placeholder="请选择类型">
            <a-select-option
              v-for="item in typeList"
              :key="item.id"
              :value="item.type"
            >
              {{ item.type }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="host:">
          <a-input
            v-model="hostAdd"
            placeholder="请输入host"
            style="width: 80%"
            allowClear
          />
        </a-form-item>
        <a-form-item label="端口:">
          <a-input
            v-model="port"
            placeholder="请输入端口"
            style="width: 80%"
            allowClear
          />
        </a-form-item>
        <a-form-item label="账号:">
          <a-input
            v-model="user"
            placeholder="请输入账号"
            style="width: 80%"
            allowClear
          />
        </a-form-item>
        <a-form-item label="x:">
          <a-input
            v-model="xAdd"
            placeholder="请输入x"
            style="width: 80%"
            allowClear
          />
        </a-form-item>
        <a-form-item label="y:">
          <a-input
            v-model="yAdd"
            placeholder="请输入y"
            style="width: 80%"
            allowClear
          />
        </a-form-item>
        <a-form-item label="启用:">
          <a-switch
            checked-children="已启用"
            un-checked-children="已禁用"
            v-model="enabled"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button type="primary" @click="addData_submit">确定</a-button>
          <a-button @click="addDataShow = false">取消</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "dataOrigin",
  data() {
    return {
      tableColumns: [
        {
          title: "序号",
          ellipsis: true,
          dataIndex: "id",
          align: "center",
          width: 100,
        },
        {
          title: "名称",
          ellipsis: true,
          dataIndex: "name",
          width: 200,
        },
        {
          title: "数据库名",
          ellipsis: true,
          dataIndex: "databaseName",
          width: 200,
        },
        {
          title: "host",
          ellipsis: true,
          dataIndex: "host",
          width: 200,
        },
        {
          title: "端口",
          ellipsis: true,
          dataIndex: "port",
          align: "center",
          width: 100,
        },
        {
          title: "账号",
          ellipsis: true,
          dataIndex: "user",
          width: 200,
        },
        {
          title: "x",
          ellipsis: true,
          dataIndex: "x",
          width: 100,
        },
        {
          title: "y",
          ellipsis: true,
          dataIndex: "y",
          width: 100,
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "enabled",
          scopedSlots: { customRender: "enabled" },
          align: "center",
          width: 100,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          align: "center",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 180,
        },
      ],
      tableData: [],
      tablePagination: {},
      currentPage: 1,
      addDataShow: false,
      name: "",
      host: "",
      x: "",
      y: "",
      addTitle: "",
      databaseNameAdd: "",
      nameAdd: "",
      hostAdd: "",
      port: "",
      user: "",
      xAdd: "",
      yAdd: "",
      enabled: true,
      loading: false,
      typeList: [
        { id: 1, type: "MYSQL" },
        { id: 2, type: "CLICKHOUSE" },
      ],
      type: undefined,
      typeAdd: undefined,
    };
  },
  mounted() {
    this.query(1);
  },
  methods: {
    // 点击新增
    addPipeline() {
      this.addTitle = "新增";
      this.nameAdd = "";
      this.databaseNameAdd = "";
      this.hostAdd = "";
      this.typeAdd = "MYSQL";
      this.port = "";
      this.user = "";
      this.xAdd = "";
      this.yAdd = "";
      this.enabled = true;
      this.addDataShow = true;
    },
    // 新增确定
    addData_submit() {
      let data = {
        name: this.nameAdd,
        databaseName: this.databaseNameAdd,
        host: this.hostAdd,
        type: this.typeAdd,
        port: this.port,
        user: this.user,
        x: this.xAdd,
        y: this.yAdd,
        enabled: this.enabled,
      };
      if (this.addTitle == "新增") {
        this.$http
          .json_post("/pipelineDatasource/addPipelineDatasource", data)
          .then((res) => {
            if (res.result == 200) {
              this.$message.success("新增成功");
              this.query();
              this.addDataShow = false;
            }
          });
      } else {
        data.id = this.editId;
        this.$http
          .json_post("/pipelineDatasource/updatePipelineDatasource", data)
          .then((res) => {
            if (res.result == 200) {
              this.$message.success("修改成功");
              this.query();
              this.addDataShow = false;
            }
          });
      }
    },
    // 修改
    modify_data(val, enable) {
      this.addTitle = "修改";
      this.editId = val.id;
      this.databaseNameAdd = val.databaseName;
      this.nameAdd = val.name;
      this.hostAdd = val.host;
      this.typeAdd = val.type;
      this.port = val.port;
      this.user = val.user;
      this.xAdd = val.x;
      this.yAdd = val.y;
      this.enabled = val.enabled;
      if (enable) {
        this.addData_submit();
      } else {
        this.addDataShow = true;
      }
    },
    // 删除
    delete_pipeline(val) {
      let data = {
        id: val.id,
      };
      this.$http
        .get("/pipelineDatasource/deletePipelineDatasource", data)
        .then((res) => {
          if (res.result == 200) {
            this.$message.success("删除成功");
            this.query();
          }
        });
    },
    // 连接测试
    test_click(val) {
      this.loading = true;
      let data = {
        id: val.id,
      };
      this.$http
        .get("/pipelineDatasource/testPipelineDatasourceConnect", data)
        .then((res) => {
          if (res.result == 200) {
            if (res.data) {
              this.$message.success("连接正常");
            } else {
              this.$message.error("连接异常");
            }
            this.loading = false;
          }
        });
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 查询
    query(index) {
      this.loading = true;
      if (index) {
        this.currentPage = index;
      }
      let data = {
        pageNo: this.currentPage,
        pageSize: 10,
        name: this.name,
        host: this.host,
        type: this.type,
        x: this.x,
        y: this.y,
      };
      this.getPipelineLogList(data);
    },
    // 查询记录数据
    getPipelineLogList(data) {
      this.$http
        .get("/pipelineDatasource/pipelineDatasourcePage", data)
        .then((res) => {
          if (res.result === 200) {
            this.tableData = res.data.records;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.currentPage_data(current),
            };
            this.loading = false;
          }
        });
    },
    // 翻页
    currentPage_data(index) {
      this.query(index);
    },
  },
};
</script>
