import { render, staticRenderFns } from "./right_menu.vue?vue&type=template&id=40b6b703&scoped=true"
import script from "./right_menu.vue?vue&type=script&lang=js"
export * from "./right_menu.vue?vue&type=script&lang=js"
import style0 from "./right_menu.vue?vue&type=style&index=0&id=40b6b703&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40b6b703",
  null
  
)

export default component.exports